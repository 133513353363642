import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
// import "./App.css";

import { RealmAppProvider } from "./providers/RealmApp";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import { BrowserRouter as Router } from "react-router-dom";
export const APP_ID = process.env.REACT_APP_REALM_APP_ID;



/* Auth 0 */

const onRedirectCallback = (appState) => {
  if (appState) {
    const appStateJSON = appState.appStateJSON;
    const queryString = `?appStateJSON=${appStateJSON}`;
    window.location.search = queryString;
  }
  history.push(appState?.returnTo || window.location.pathname);
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};
ReactDOM.render(
  <React.StrictMode>

      <Auth0Provider {...providerConfig}>
        <RealmAppProvider appId={APP_ID}>
          <Router>
            <App />
          </Router>
        </RealmAppProvider>
      </Auth0Provider>

  </React.StrictMode>,
  document.getElementById("root")
);

import React, { useEffect, useState } from "react";
import AuthNav from "./auth-nav";
import { useAuth0 } from "@auth0/auth0-react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useRealmApp } from "../providers/RealmApp";
const NavBar = () => {
  const ctechapp = useRealmApp();
  const { isAuthenticated, user } = useAuth0();
  const [companyLogo, setcompanyLogo] = useState("/images/app_logo.png");
  const [companySmallLogo, setcompanySmallLogo] = useState(
    "/images/app_small_logo.png"
  );
  useEffect(() => {
    if (isAuthenticated) getUserInfoByID();
  }, [isAuthenticated, companyLogo]);
  async function getUserInfoByID() {
    try {
      
    } catch (exception) {
      console.error(exception);
    }
  }
 

  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container className="d-flex justify-content-between">
        <Navbar.Brand href="#contactlist">
          {companyLogo === "/images/app_logo.png" && (
            <>
              <img
                src="/images/app_logo.png"
                width="200px"
                className="headerLogo d-none d-md-block"
              ></img>
              <img
                src="/images/app_small_logo.png"
                width="100px"
                className="headerSmallLogo d-md-none"
              ></img>
            </>
          )}
          {companyLogo !== "/images/app_logo.png" && (
            <>
              <img
                src={companyLogo}
                width="200px"
                height="80px"
                className="headerLogo d-none d-md-block"
              ></img>
              <img
                src={companySmallLogo}
                width="100px"
                height="40px"
                className="headerSmallLogo d-md-none"
              ></img>
            </>
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {isAuthenticated && <Nav className="me-auto"></Nav>}
          <AuthNav />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavBar;

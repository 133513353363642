//services for getLOBApps
export const getLOBApps = async (Ctechapp, userinfo) => {
  try {
    const data = await Ctechapp.functions.getLOBApps(userinfo);
    if (data !== undefined) {
      // console.log("data of getLOBApps: " + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getLOBAppsEnvironment
export const getLOBAppsEnvironment = async (appname, appenv, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getLOBAppsEnvironment(
      appname,
      appenv
    );
    if (data !== undefined) {
      // console.log("data of getLOBAppsEnvironment: " + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getLOBAppsEnvironmentInfoByAppID
export const getLOBAppsEnvironmentInfoByAppID = async (Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getLOBAppsEnvironmentInfoByAppID();
    if (data !== undefined) {
      // console.log("data of getLOBAppsEnvironmentInfoByAppID: " + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

import React, { useEffect } from "react";
import {
  useGlobalFilter,
  useTable,
  useSortBy,
  usePagination,
  useColumnOrder,
  useFilters,
} from "react-table";
import { useHistory } from "react-router";
export default function GenericSimpleTable({
  gcolumns,
  gdata,
  ghiddencolumns,
  gkey,
  gkey1,
  glink,
}) {
  //const history = useHistory();
  // Use the useTable Hook to send the columns and data to build the table
  const getData = () => {
    if (gdata !== "") {
      var arr = [];
      var obj = JSON.parse(gdata);
      for (var i in obj) arr.push(obj[i]);
      return arr;
    }
    return [];
  };
  const data = React.useMemo(() => getData(), [gdata]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {},
  } = useTable(
    {
      columns: gcolumns,
      data,
      initialState: { hiddenColumns: ghiddencolumns, pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useColumnOrder
  ); // useFilters!

  return (
    <div className="table-responsive mb-0">
      <table
        className="table table-hover table-panel align-middle mb-3"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="d-flex align-items-center">
                    <span>{column.render("Header")}</span>
                    <span className="ms-auto">
                      {column.sortable ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fa fa-sort-down fa-fw fs-14px text-blue"></i>
                          ) : (
                            <i className="fa fa-sort-up fa-fw fs-14px text-blue"></i>
                          )
                        ) : (
                          <i className="fa fa-sort fa-fw fs-14px opacity-3"></i>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <span>
                      {column.canFilter ? column.render("Filter") : null}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className="highlight"
                style={{ cursor: "pointer" }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  if (cell.column.Header === "Action" || gkey === "") {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  } else {
                    return (
                      <td
                        {...cell.getCellProps({
                          onClick: (e) => {
                            // history.push({
                            //   pathname: glink,
                            //   state: {
                            //     [gkey]: row.allCells[0].value,
                            //     [gkey1]: row.allCells[1].value,
                            //   },
                            // });
                          },
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

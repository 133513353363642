import React from "react";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import * as Realm from "realm-web";

import Listapps from "./pages/apps/listapps";

//AUth0*/
import NavBar from "./components/NavBar";
import Footer from "./components/footer";

import { useAuth0 } from "@auth0/auth0-react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getAccessTokenVerifyByToken,
  get_environment_config_value,
} from "./services/applications_services";
import { useLocation } from "react-router-dom";
// fontawesome

//export const audience = process.env.REACT_APP_AUTH_CP_MGMTAPILIENTID;
export const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;
const App = (props) => {
  /* Auth 0 */
  const location = useLocation();
  const { isAuthenticated, user, loginWithRedirect } = useAuth0();

  // const [client, setClient] = useState(null);
  //const [mongodbuser, setmongodbUser] = useState(null);
  //const [app, setApp] = useState(useRealmApp());
  const [userInfo, setUserInfo] = useState();
  // const [authClient, setoauthClient] = useState(null);
  let localapp = new Realm.App(REALM_APP_ID);
  const [devenv, setDevEnv] = useState("");
  const [isFailure, setisFailure] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [errmessage, setErrMessage] = useState("");
  const [message, setMessage] = useState("");

  //const messages
  const accessdeniedmsg = "Access denied (invalid access token)";
  const connectionsuccessmsg = "Connected successfully";
  const connectionfailuremsg = "Connection failed";
  useEffect(() => {
    window.addEventListener("message", handleCallbackPostMessage);
    const queryparams = new URLSearchParams(location.search);
    async function checkUser() {
      try {
        if (!isAuthenticated) {
          let paramsinfo = queryparams.get("data");
          const appStateJSON = encodeURIComponent(JSON.stringify(paramsinfo));
          if (paramsinfo) {
            const options = {
              appState: { appStateJSON: appStateJSON },
              redirectUri: window.location.origin,
            };
            await loginWithRedirect(options);
          }
          // else {
          //   setisFailure(true);
          //   setErrMessage(accessdeniedmsg)
          // }
        } else {
          const queryparams = new URLSearchParams(location.search);

          let appStateJSON = queryparams.get("appStateJSON");
          console.log("appStateJSON", appStateJSON);
          if (appStateJSON) {
            const paramsinfo = JSON.parse(appStateJSON);
            // Get the API key from the local environment
            const apiKey = process.env.REACT_APP_APIKEY;
            if (!apiKey) {
              throw new Error("Could  not find a Realm Server API Key.");
            }
            // Create an api key credential
            const credentials = Realm.Credentials.apiKey(apiKey);

            try {
              localapp.logIn(credentials).then(() => {
                getAccessTokenVerifyByTokenValidate(
                  paramsinfo,
                  localapp.currentUser
                );
              });
            } catch {
              if (
                localapp.currentUser !== undefined &&
                localapp.currentUser !== null
              )
                localapp.logOutAllUsers();
            }
          }
        }
      } catch (error) {}
    }
    checkUser();
  }, [user, isAuthenticated]);
  async function getAccessTokenVerifyByTokenValidate(token, currentUser) {
    if (currentUser !== undefined && currentUser !== null)
      getAccessTokenVerifyByToken(currentUser, currentUser.id, token).then(
        async (response) => {
          if (response !== undefined) {
            let userData = JSON.parse(response);

            if (userData.istokenvalid === "true") {
              await get_environment_config(currentUser, userData);
              setUserInfo(userData);
            } else {
              setisFailure(true);
              setErrMessage(accessdeniedmsg);
            }
          }
        }
      );
  }

  async function get_environment_config(currentUser, userinfo) {
    get_environment_config_value(currentUser, userinfo).then((response) => {
      if (response !== undefined) {
        setDevEnv(response?.toLowerCase());
      }
    });
  }

  /*LinkedinProfile*/
  const handleCallbackPostMessage = (event) => {
    if (event.data.type === "qbtoken") {
      setisSuccess(true);
      setMessage(connectionsuccessmsg);
    }
    else if (event.data.type === "procoretoken") {
      setisSuccess(true);
      setMessage(connectionsuccessmsg);
    }
    else if (event.data.type === "sharepointtoken") {
      setisSuccess(true);
      setMessage(connectionsuccessmsg);
    }
    else if (event.data.type === "entraidtoken") {
      setisSuccess(true);
      setMessage(connectionsuccessmsg);
    }
    else if (event.data.type === "salesforcetoken") {
      setisSuccess(true);
      setMessage(connectionsuccessmsg);
    }
    else
    {
      console.log("event.data.type is not matching : ",event.data.type);
    }
    
  };

  /*qbtoken*/
  // const updateQBToken = (qbtoken) => {
  //   if (
  //     qbtoken.callbackpage === "editapps" ||
  //     qbtoken.callbackpage === "addapps"
  //   ) {
  //     setisSuccess(true);
  //     setMessage(connectionsuccessmsg);
  //     // window.location.href = "/editapps/" + qbtoken.keyid;
  //   } else {
  //     //window.location.href = "/QBConnect/" + qbtoken.companyid;
  //     setisFailure(true);
  //     setErrMessage(connectionfailuremsg);
  //   }
  // };

  // /*procoretoken*/
  // const updateProcoreToken = (procoretoken) => {
  //   if (
  //     procoretoken.callbackpage === "editapps" ||
  //     procoretoken.callbackpage === "addapps"
  //   ) {
  //     //window.location.href = "/apps";
  //     setisSuccess(true);
  //     setMessage(connectionsuccessmsg);
  //   }
  // };
  // /*procoretoken*/
  // const updateSharepointToken = (sharepointtoken) => {
  //   if (
  //     sharepointtoken.callbackpage === "editapps" ||
  //     sharepointtoken.callbackpage === "addapps"
  //   ) {
  //     //window.location.href = "/apps";
  //     setisSuccess(true);
  //     setMessage(connectionsuccessmsg);
  //   }
  // };

  const handleFailureSweetAlert = () => {
    setisFailure(false);
    setErrMessage("");
    handleClose();
  };
  const handleSuccessSweetAlert = () => {
    setisSuccess(false);
    setMessage("");
    handleClose();
  };
  const handleClose = () => {
    setisSuccess(false);
    setMessage("");
    try {
      if (localapp.currentUser !== undefined && localapp.currentUser !== null)
        localapp = null;
    } catch (error) {}

    window.close();
  };
  return (
    <div id="app" className="d-flex flex-column h-100">
      <NavBar />
      <Container className="flex-grow-1">
        {isAuthenticated && userInfo && devenv && (
          <Listapps
            userinfo={userInfo}
            ctechapp={localapp}
            devenv={devenv}
            onHandleClose={handleClose}
          />
        )}
      </Container>
      <Footer />

      <div>
        {isFailure && (
          <SweetAlert
            warning
            style={{ color: "black" }}
            title={"App master"}
            onConfirm={handleFailureSweetAlert}
          >
            {errmessage}
          </SweetAlert>
        )}
        {isSuccess && (
          <SweetAlert
            sucess
            style={{ color: "black" }}
            title={"App master"}
            onConfirm={handleSuccessSweetAlert}
          >
            {message}
          </SweetAlert>
        )}
      </div>
    </div>
  );
};

export default App;

export const getapplicationsbyid = async (app_id, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getapplicationsbyid(
      app_id,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAdminVerificationTaskByParent = async (
  apprelationship,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getAdminVerificationTaskByParent(
      apprelationship,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const get_environment_config_value = async (Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.get_environment_config_value(
      loggedin_by
    );
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCommandpostAccountinfoByEmail = async (
  Ctechapp,
  loggedin_by
) => {
  try {
    var obj = {
      email: loggedin_by.email,
    };
    const data = await Ctechapp.functions.getCommandpostAccountinfoByEmail(
      obj,
      loggedin_by
    );
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateApplicationByID = async (app, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.updateApplicationByID(
      JSON.stringify(app),
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateApplicationByLogo = async (app, Ctechapp, loggedin_by) => {
  console.log("logo", app);
  try {
    const data = await Ctechapp.functions.updateApplicationByLogo(
      JSON.stringify(app),
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getapplistbyuserid = async (Ctechapp, userinfo) => {
  try {
    const data = await Ctechapp.functions.getapplistbyuserid(userinfo);
    if (data !== undefined) {
      //console.log("data of getapplistbyuserid :" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAccessTokenVerifyByToken = async (
  Ctechapp,
  loggedin_by,
  token
) => {
  try {
    const data = await Ctechapp.functions.getAccessTokenVerifyByToken(token);
    if (data !== undefined) {
      //console.log("data of getapplistbyuserid :" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const insertApplication = async (appinfo, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.insertApplication(
      appinfo,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteAppByuserID = async (_id, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.deleteAppByuserID(_id, loggedin_by);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateApplicationRelationshipByID = async (
  app,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.updateApplicationRelationshipByID(
      JSON.stringify(app),
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const isValidApplication = async (app, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.isValidApplication(
      JSON.stringify(app),
      loggedin_by
    );
    if (data !== undefined) {
      return data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

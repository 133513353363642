import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useRealmApp } from "../providers/RealmApp";
const AuthNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showmenu, setshowmenu] = useState(true);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const ctechapp = useRealmApp();

  const logoutWithRedirect = () => {
    ctechapp.logOutAllUsers();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  useEffect(() => {
    setshowmenu(true);
  }, [window.location.pathname, showmenu]);
  return (
    <Nav className="mr-auto">
      {showmenu && isAuthenticated && ctechapp.currentUser !== null && (
        <NavDropdown
          title={user && <h6 className="d-inline-block">{user.name}</h6>}
          id="collasible-nav-dropdown"
        >
          <NavDropdown.Item>
            {/* <FontAwesomeIcon icon="power-off" /> */}
            <RouterNavLink
              to="#"
              id="qsLogoutBtn"
              onClick={() => logoutWithRedirect()}
            >
              &nbsp; Log out
            </RouterNavLink>
          </NavDropdown.Item>
        </NavDropdown>
      )}
    </Nav>
  );
};

export default AuthNav;
